<template>
  <v-sheet
    :height="$vuetify.breakpoint.mdAndUp ? '100vh' : '100vh'"
    color="primary"
    tile
  >
    <v-img
      :key="image"
      :src="image"
      gradient="to top, rgba(0,0,0, .10), rgba(0,0,0, .50)"
      height="100%"
      position="top"
    >
      <v-container
        fill-height
        fluid
        class="py-0"
      >
        <!-- <v-row class="fill-height"> -->
        <v-row>
          <v-col justify="center" :align="$vuetify.breakpoint.mdAndUp ? 'center' : ''" class="ml-4">
            <h2
              :class="$vuetify.breakpoint.smAndUp ? 'display-3' : 'display-1'"
              class="mb-2 white--text"
              v-text="title"
            />
            <h1
              class="title font-weight-light white--text"
              v-html="subtitle"
            />
          </v-col>
          <!-- <v-col class="pa-0">
            <v-sheet color="rgba(255,255,255, .10)" height="75%" width="75%" class="pa-6" rounded>
              <h2>A Place Where You Can Grow</h2>
              <v-btn class="ma-5">Schedule an Appointment</v-btn>
              <v-btn class="ma-5">About Doug</v-btn>
            </v-sheet>
          </v-col> -->
        </v-row>
      </v-container>
    </v-img>
  </v-sheet>
</template>

<script>
  export default {
    data: () => ({
      loaded_title: "",
      loaded_subtitle: "",
      titles: {
        home: {
          title: 'Every Journey Begins with a Step',
          subtitle: 'Partnering with you for help and lasting <br> growth through Counseling or Consulting.'
        },
        individual: {
          title: 'Next Generation Development Today',
          subtitle: 'Phasellus gravida semper nisi. Fusce convallis metus id felis luctus adipiscing.'
        },
        marriage: {
          title: 'Premium Quality and Branding',
          subtitle: 'Vestibulum turpis sem, aliquet eget, lobortis pellentesque.'
        },
        family: {
          title: 'Contact Alpha Construction',
          subtitle: 'Aenean viverra rhoncus pede. Morbi mollis tellus ac sapien.'
        },
        trauma: {
          title: 'Contact Alpha Construction',
          subtitle: 'Aenean viverra rhoncus pede. Morbi mollis tellus ac sapien.'
        },
        christian: {
          title: 'Contact Alpha Construction',
          subtitle: 'Aenean viverra rhoncus pede. Morbi mollis tellus ac sapien.'
        },
        about: {
          title: 'Contact Alpha Construction',
          subtitle: 'Aenean viverra rhoncus pede. Morbi mollis tellus ac sapien.'
        },
        // contact: {
        //   title: 'Contact Doug Feil',
        //   subtitle: 'Aenean viverra rhoncus pede. Morbi mollis tellus ac sapien.'
        // }
      }
    }),methods: {
      loadtitle(){
        this.loaded_title = this.titles[this.namespace].title
      },
      loadsubtitle(){
        this.loaded_subtitle = this.titles[this.namespace].subtitle
      }
    },mounted(){
      
    },
    computed: {
      namespace () {
        // console.log(this.$route)
        if (!this.$route || !this.$route.name) return ''
        // this.loaded_title = ""
        // this.loaded_subtitle = ""
        // setTimeout(()=>{this.loadtitle()}, 1000)
        // setTimeout(()=>{this.loadsubtitle()}, 1500)
        return this.$route.name.toLowerCase()
      },
      image () {
        return '/static/' + this.namespace + '-hero.png'

      },
      subtitle () {
        return this.titles[this.namespace].subtitle
      },
      title () {
        return this.titles[this.namespace].title
      }
    }
  }
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 2.0s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
